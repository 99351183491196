import * as objectApi from '@/api/object.js';

export default {
	namespaced: true,
	state: {
		object:null,
		key_page_text:0, 
		quill_key:0,
		save_status:false, 
	},
	getters: { 
		object_place:state=>{
			return state
		},  
	},
	mutations: { 
		setObject(state, object){ 
			state.object = object; 
			state.quill_key++;
		},  
		setUrl(state, url){ 
			state.object.url = url; 
			state.save_status = true;
		}, 
		setMarkerTitle(state, title){ 
			state.object.marker_title = title; 
			state.save_status = true;
		}, 
		setCoordinateLtd(state, val){ 
			if(state.object.coordinates == null) state.object.coordinates = [];
			state.object.coordinates[0] = val; 
			state.save_status = true;
		}, 
		setCoordinateLgd(state, val){ 
			if(state.object.coordinates == null) state.object.coordinates = [];
			state.object.coordinates[1] = val; 
			state.save_status = true;
		}, 
		setTitle(state, title){ 
			state.object.title = title; 
			state.save_status = true;
		}, 
		setDesc(state, desc){ 
			state.object.desc = desc; 
			state.save_status = true;
		}, 
		setText(state, text){ 
			state.object.text[state.key_page_text].text = text; 
			state.save_status = true;
		},
		setTextPageName(state, name){ 
			state.object.text[state.key_page_text].name = name; 
			state.save_status = true;
		},
		setImages(state, images){ 
			state.object.images = images;  
			state.save_status = true;
		},
		setImagesText(state, data){ 
			state.object.text[state.key_page_text].images.push(data.url);  
			state.save_status = true;
		}, 
		addPageText(state){  
			state.object.text.push({
				name:"Новый раздел",
				text:null,
				images:[]
			}) 
			state.save_status = true;
		},
		setPageText(state, key){  
				state.key_page_text = key; 
				state.quill_key++; 
		},
		deletePageText(state){   
			state.object.text.splice(state.key_page_text, 1);
			state.key_page_text = state.key_page_text-1; 
			state.quill_key++; 
			state.save_status = true;
		},
		setObjectType(state, id){  
			state.object.type_id = id; 
			state.save_status = true;
		},
		setCountries(state, id){  
			state.object.country_id = id; 
			state.save_status = true;
		},
		setRegions(state, id){  
			state.object.region_id = id; 
			state.save_status = true;
		},
		setDistricts(state, id){  
			state.object.district_id = id; 
			state.save_status = true;
		},
		setCitys(state, id){  
			state.object.city_id = id; 
			state.save_status = true;
		},
		setSeoTitle(state, title){  
			state.object.seo_title = title; 
			state.save_status = true;
		},
		setSeoDesc(state, desc){  
			state.object.seo_desc = desc; 
			state.save_status = true;
		},
		setSeoKeywords(state, keywords){  
			state.object.seo_keywords = keywords; 
			state.save_status = true;
		},
		setSeoKeyword(state, keyword){  
			state.object.seo_keyword = keyword; 
			state.save_status = true;
		},
		setSaveStatus(state, status){
			state.save_status = status;
		},
		setImagesObject(state, images){
			state.object.images = images;
		}, 
	},
	actions: {      
		async createObject(){
			let response = await objectApi.createObject();
			return response
		},
		async getObject({ commit }, id){
			await objectApi.getObject({id}).then((response)=>{
				if(response.result){
					let object = response.object;  
					commit('setObject', object);
				}	
			});
		},
		async saveObject({ state, commit, dispatch }){

			let data = new FormData(); 
			data.append('object', JSON.stringify(state.object));
			await objectApi.upObject(data).then((response)=>{
				if(response.result){
					commit("setSaveStatus", false);
					dispatch('getObject', state.object.id)
				}
			}) 
		},
		publishObject({ state, dispatch }, status){
			dispatch('checkObject').then(async (check_response)=>{
				if(check_response){
					await objectApi.publishObject({id:state.object.id, status}).then((response)=>{
						if(response.result){
							dispatch('getObject', state.object.id)
						}
					});
				}
			})
		},
		async deleteObject({ state }){
			return await objectApi.deleteObject({ id:state.object.id });
		},
		async getImagesObject({ state, commit }){
			await objectApi.getImagesObject({id:state.object.id}).then((response)=>{
				if(response.result){
					commit('setImagesObject', response.images)
				}
			});
		},
		async changeFile({ state, dispatch }, event){

			let data = new FormData(); 
			data.append('id', state.object.id);
			data.append('file', event.target.files[0]);

			await objectApi.loadImageObject(data).then((response)=>{
				if(response.result){
					dispatch('getImagesObject')
				}
			});
		},
		async deleteImage({ state, dispatch }, url){
			await objectApi.deleteImageObject({ id:state.object.id, url }).then((response)=>{
				if(response.result){
					dispatch('getImagesObject')
				}
			});
		},  
		async checkObject({ state, dispatch }){

			let message = null;
 
			if(state.object.url == null) 
				message = "Введите URL";

			if(message == null && state.object.url.length < 5) 
				message = "URL должен быть больше 5 символов";
			
			if(message == null && state.object.url !== null) 
				await objectApi.checkUrlObject({url:state.object.url, id:state.object.id}).then((response)=>{
					if(!response.result)
						message = "URL уже занят";
				});

			if(message == null && (state.object.marker_title == null || state.object.marker_title.length < 2))
				message = "Введите заголовок маркера";

			if(message == null && (state.object.coordinates == null || state.object.coordinates.length < 2))
				message = "Введите координаты";

			if(message == null && state.object.title == null) 
				message = "Введите заголовок";

			if(message == null && state.object.title.length < 10) 
				message = "Заголовок больше 10 символов";

			if(message == null && state.object.desc == null) 
				message = "Введите описание";

			if(message == null && state.object.desc.length < 30) 
				message = "Описание больше 30 символов";

			if(message == null){
				if(state.object.text.length == 0)
					message = "Добавьте основной текст"				
				else
					for(let key in state.object.text)
						if(state.object.text[key].text == null)
							message = "Раздел "+state.object.text[key].name+" не содержит текста";
			}

			if(message == null && state.object.type_id == null) 
				message = "Выберите тип объекта";

			if(message == null && state.object.country_id == null) 
				message = "Выберите страну";

			if(message == null && state.object.region_id == null) 
				message = "Выберите субъект";

			if(message == null && state.object.seo_title == null) 
				message = "Введите seo заголовок";

			if(message == null && state.object.seo_title.length < 20) 
				message = "Seo заголовок больше 20 символов";

			if(message == null && state.object.seo_desc == null) 
				message = "Введите seo описание";

			if(message == null && state.object.seo_desc.length < 20) 
				message = "Seo описание больше 20 символов";

			if(message == null && state.object.seo_keywords == null) 
				message = "Введите seo ключевые слова";

			if(message == null && state.object.seo_keywords.length < 10) 
				message = "Seo ключевые слова больше 10 символов";
			
			if(message == null && state.object.seo_keyword.length < 5) 
				message = "Seo ключевые слова больше 5 символов";
		
			if(message !== null)
				dispatch('notify/setSnackbar', {show:true, text:message}, {root:true})

			return message == null ? true : false;
		},
	}, 
}